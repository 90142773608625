.buttonclass {
    border-radius: 5px;
    padding: 10px;
    font-size: 12px;
    margin: 8px;
    border: none;
    background-color: #F1F3F4;
  }
  .clicked {
    /* Styles for clicked buttons */
    background-color: lightblue;
  }