.tooth-chart-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
.tooth-chart {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .tooth-row {
    display: flex;
    justify-content: center;
  }
  
  .tooth-section {
    display: flex;
    flex-wrap: wrap;
  }
  
  .tooth-btn {
    margin: 5px;
    padding: 10px;
    border: 2px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
    background-color: #f0f0f0;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .tooth-btn.selected {
    background-color: #3a7a91;
    color: white;
  }
  
  .tooth-btn:focus {
    outline: none;
  }
  
  /* Adjust button appearance when pressed */
  .tooth-btn:active {
    transform: translateY(1px);
  }
  