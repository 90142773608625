/* Add this to your CSS file */
.left-button {
    cursor: pointer;
    margin-left: 20px;
    border-radius: 50%;
    width: 40px; /* Adjust width as needed */
    height: 40px;
    background-color: ghostwhite;
    display: flex;
    align-items: center;
    padding-left: 10px;
  }
  
  .left-button.active {
    background-color: lightblue; /* Change color for active state */
    font-weight: bold; /* Optionally, you can add other styles for the active state */
  }
  