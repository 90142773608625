.leftBar{
    margin-top: 5px;
    width:60px;
    height:75vh;
    color: white;

    box-shadow: 1px 1px 5px 1px #e9ebfa;
    /* background-color: rgb(233, 234, 233); */
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
  padding-top: 30px;
    /* align-items: center; */
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
    background-color:rgba(12, 17, 52, 0.973) ;

}
.leftBar_component{
    color: rgba(255, 255, 255, 0.7);
    width: 90%;
    height: 90px;
    display: flex;
    /* background-color: aqua; */
    
    margin-bottom: 10px;
    justify-content:center;
    align-items: center;
    
    border-top: 1px solid rgba(237, 237, 237, 0.233);
    font-size: 14px;
    writing-mode: tb-rl;
    transform: rotate(-180deg);
}
.rightBar{
  width: 90%;
  margin-right: 10px;
}
.patientBox{
  /* width: 97%; */
  /* height: 82px; */
 color: rgb(32, 84, 131);
 border: 1px solid rgba(1, 1, 102, 0.141);
 border-radius: 5px;
 background-color: white;



 
 margin: 5px 10px 5px 5px;

 
 /* box-shadow: 3px 2px 5px 2px #e9ebfa; */

}