.bottombar {
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  border-top: 3px solid rgb(231, 233, 251);
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  width: 100%;
  height: 65px;
  padding-top: 5px;
  margin-top: 1px;
  font-size: small;
  background-color: rgb(10, 13, 37);
  position: fixed;
  bottom: 0;
  z-index: 2;
}

.icon_Name {
  padding: 2px;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.icon_Name p {
  margin: 0px;
  font-size: 10px;
}

@media (min-width: 768px) {
  .bottombar {
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    border-top: 3px solid;
    /* border-left: 3px solid rgb(231, 233, 251); */
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 30px;
    border-top-right-radius: 30px;
    width: 100px;
    height: 100%;
    padding-top: 60px;
    left: 0;
    bottom: unset;
    top: 0;
  }

  .icon_Name {
    padding: 15px 0;
  }

  .icon_Name p {
    margin-top: 5px;
    font-size: 12px;
  }

}
