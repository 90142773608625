.onexam-container {
  
  }
  
  .selected-teeth {
    margin-bottom: 20px;
  }
  
  .selected-title {
    font-size: 18px;
    font-weight: bold;
  }
  
  .selected-teeth-list {
    font-size: 16px;
  }
  
  .action-buttons {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
  
  .action-button {
    margin: 0 10px;
  }
  
  .investigation-button {
    position: fixed;
    bottom: 1px;
    left: 50%;
    transform: translateX(-50%);
  }
  
  .investigation-button button {
    width: 200px;
    border: none;
    background-color: #3a7a91;
    color: white;
    font-size: 16px;
    font-weight: bold;
    padding: 10px;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  
  .investigation-button button svg {
    margin-left: 5px;
  }
  