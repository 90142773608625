.hello{
    width: 98%;
    height: 50px;
   color: rgb(32, 84, 131);
   /* border: 1px solid rgba(1, 1, 102, 0.141); */
   /* border-radius: 5px; */
  
  
   padding: 0px;
   /* margin: 10px; */
   margin-right: 15px;
   
  
   
   /* box-shadow: 3px 2px 5px 2px #e9ebfa; */
  
  }




  .custom-date-picker {
    position: relative;
    display: inline-block;
  }
  
  input {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
  }
  
  .calendar-icon {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    cursor: pointer;
    font-size: 20px;
  }
  
  .calendar-container {
    position: absolute;
    top: 100%;
    left: 0;
    background-color: white;
    border: 1px solid #ccc;
    border-top: none;
    border-radius: 0 0 4px 4px;
    padding: 10px;
    display: inline-block;
    z-index: 1;
  }
  .moneydisply { 
    display: block; /* Show by default */
}


.startOPdBtn{
  margin-bottom: 80px;
}
@media (min-width: 768px) {
    .moneydisply { 
        display: none; /* Hide on screens 768px and larger */
    }
    .topSec{

      border-radius: 10px;
      margin:0px 5px 0px 5px
    }

    .startOPdBtn{
      margin-bottom: 10px;
    }
}