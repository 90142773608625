.searchBar{
   
    bottom: 80px;
      display: flex;
      justify-content: space-around;
      border-radius: 10px;
  width: 90%;

}
.userSent{
  display: flex;
  position: relative;
  background-color: rgba(212, 201, 156, 0.855);
  border-radius: 0px 12px 12px 12px;
  margin-right: 15px;
  /* right: 0; */
  
}
.drsent{
  display: flex;
  margin-left: auto;
  position: relative;
  border-radius: 12px 12px 0px 12px;
  background-color: rgb(222, 220, 249);
  
  /* position: absolute; */
/* right: 0; */

}